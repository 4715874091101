import { forwardRef, useEffect, useRef } from 'react'
import { Dialog, DialogContent, DialogContentText, Slide, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectThreeDS, sendValidade } from 'reducers/threeds'
import { paymentLinkApiEndpoint } from 'config'

import useRudderStack from 'utils/useRudderStack'

const PopupTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default withStyles((theme) => ({
  paper: {
    background: 'none',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
    },
  },
  scrollBody: {
    // backgroundColor: 'black',
    verticalAlign: 'top',
  },
  paperScrollBody: {
    backgroundColor: 'black',
    margin: '0 !important',
    maxWidth: 'calc(100% - 12px) !important',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 12px) !important',
    },
  },
  backdrop: {
    // backgroundColor: theme.palette.common.black,
  },
  dialogContent: {
    '&:first-child': {
      padding: 0,
    },
    textAlign: 'center',

    margin: 0,
    padding: 0,
  },

  boxContent: {
    textAlign: 'center',
    minHeight: 300,
    minWidth: 300,
    backgroundColor: 'white',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  iframe: {
    border: 'none',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
}))(({ open = true, source, classes, onClose, data, ...props }) => {
  const { rudderStack } = useRudderStack()

  const threeds = useSelector(selectThreeDS)
  const dispatch = useDispatch()

  const formRef = useRef(null)

  useEffect(() => {
    if (formRef.current) {
      setTimeout(() => {
        if (formRef && formRef.current) {
          formRef.current.submit()
        }
      }, 200)
    }
  }, [formRef.current])

  useEffect(() => {
    const handler = (event) => {
      if (event.origin === new URL(paymentLinkApiEndpoint).origin) {
        let params = JSON.parse(event.data)

        dispatch(sendValidade({ ...params, rudderStack }))
      }
    }
    const eventListener = window.addEventListener('message', handler, false)
    return () => {
      window.removeEventListener('message', handler)
    }
  }, [dispatch, rudderStack, sendValidade])

  const styleIframe = {
    width: threeds.challenge.width || 'calc(100vw - 15px)',
    height: threeds.challenge.height || 'calc(100vh - 15px)',
  }

  return (
    <>
      <Dialog
        {...props}
        classes={{
          paper: classes.paper,
          paperScrollBody: classes.paperScrollBody,
          scrollBody: classes.scrollBody,
        }}
        open={open}
        onClose={onClose}
        TransitionComponent={PopupTransition}
        BackdropProps={{
          className: classes.backdrop,
        }}
        scroll="body"
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.dialogContent}>
          <DialogContentText style={{ margin: 0 }} id="alert-dialog-description" component="div">
            <Box className={classes.boxContent} style={styleIframe}>
              <iframe name={'ddc-iframe'} style={styleIframe} className={classes.iframe}></iframe>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <form ref={formRef} action={threeds.challenge.source} method="POST" target={'ddc-iframe'}>
        <input type="hidden" name="JWT" value={threeds.challenge.jwt} />
      </form>
    </>
  )
})
