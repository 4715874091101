import { IDPaySDK } from 'idpay-b2b-sdk'

import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectUnicoPay, sendValidade } from 'reducers/unicopay'

import useRudderStack from 'utils/useRudderStack'
import debounce from 'lodash.debounce'

const UnicoPay = () => {
  const { rudderStack } = useRudderStack()

  const { unicopay, start, transaction } = useSelector(selectUnicoPay)
  const dispatch = useDispatch()

  const onFinish = useCallback(
    debounce((transaction) => {
      dispatch(
        sendValidade({
          rudderStack,
        }),
      )
    }, 300),
    [dispatch, sendValidade, rudderStack],
  )

  const initUnicoPay = async () => {
    await IDPaySDK.init({
      type: 'IFRAME',
    })

    if (start && unicopay?.reference_id) {
      rudderStack?.track('Payment Link | UnicoPay | Unico Started', {
        brand: transaction?.receipt?.cardType,
        nsu: transaction?.nsu,
        amount: transaction?.receipt?.amount,
        merchant_id: transaction?.handle,
      })

      IDPaySDK.open({
        transactionId: unicopay?.reference_id,
        onFinish,
      })
    }
  }

  useEffect(() => {
    if (start) {
      initUnicoPay()
    }
  }, [start])

  return <></>
}

export default UnicoPay
