import NewHeader from 'components/NewHeader'
import LandingBox from 'components/LandingBox'

import { usePaymentContext } from 'context/PaymentContext'
import { useSelector } from 'react-redux'
import { selectRedirectReceipt } from 'reducers/redirectReceipt'
import PaymentContainer from 'containers/PaymentContainer'
import useRudderStack from 'utils/useRudderStack'
import { useEffect } from 'react'
import LoadingContainer from './LoadingContainer'

function LandingContainer(props) {
  const { rudderStack, ready: rudderStackReady } = useRudderStack()
  const config = usePaymentContext()
  const redirect = useSelector(selectRedirectReceipt)
  const renderPayment = !config.userData?.limit_exception && config.amount

  useEffect(() => {
    if (rudderStackReady) {
      rudderStack.page('Payment Link Page')
    }
  }, [rudderStackReady])

  return (
    <>
      <LandingBox>
        <NewHeader />
        {renderPayment && <PaymentContainer />}
      </LandingBox>

      {redirect.active && <LoadingContainer />}
    </>
  )
}

export default LandingContainer
