import PaymentForm from 'components/PaymentForm'
import ThreeDS from 'components/ThreeDS'
import UnicoPay from 'components/UnicoPay'

function PaymentContainer({ userData, ...props }) {
  return (
    <>
      <PaymentForm {...props} />
      <ThreeDS />
      <UnicoPay />
    </>
  )
}

export default PaymentContainer
