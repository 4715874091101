import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectThreeDS } from 'reducers/threeds'

import useRudderStack from 'utils/useRudderStack'

import Challenge from './Challenge'
import FormPost from './FormPost'

const ThreeDS = () => {
  const { rudderStack } = useRudderStack()

  const threeds = useSelector(selectThreeDS)

  useEffect(() => {
    if (threeds?.form_post) {
      rudderStack?.track('Payment Link | 3DS | Process Started', {
        amount: threeds?.transaction?.receipt?.amount,
        brand: threeds?.transaction?.receipt?.cardType,
        nsu: threeds?.transaction?.nsu,
        merchant_id: threeds?.transaction?.handle,
      })
    }
  }, [threeds.form_post])

  useEffect(() => {
    if (threeds?.challenge) {
      rudderStack?.track('Payment Link | 3DS | Challenge Started', {
        amount: threeds?.transaction?.receipt?.amount,
        brand: threeds?.transaction?.receipt?.cardType,
        nsu: threeds?.transaction?.nsu,
        merchant_id: threeds?.transaction?.handle,
      })
    }
  }, [threeds.challenge])

  return (
    <>
      {threeds.form_post && <FormPost {...threeds.form_post} />}
      {threeds.challenge && <Challenge />}
    </>
  )
}
export default ThreeDS
