import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FORM_ERROR } from 'final-form'
import * as Y from 'yup'
import { Grid, Collapse, Typography, Hidden, IconButton, Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { selectZipCodeSearch, fetchAddressByZipcode } from 'reducers/zipcode'
import { selectPayments, createPayment, hideForm } from 'reducers/payments'
import { selectSession } from 'reducers/session'
import { usePaymentContext } from 'context/PaymentContext'
import Footer from 'components/Footer'
import Popup from 'components/ui/Popup'
import Popover from 'components/ui/Popover'
import { useRouter } from 'next/router'
import ELEMENT_TEST_ID from '/playwright/support/elements'

import { SubmitButton, useFormSchemaContext } from 'components/ui/form'
import Form, {
  PhoneNumber,
  Email,
  Installments,
  CardNumber,
  CardExpDate,
  CardCVV,
  CardHolderName,
  // CPF,
  CPFCNPJ,
  CEP,
  Street,
  City,
  StreetNumber,
  State,
} from 'components/ui/form/PaymentForm'
import { warnings, warningFindByCode } from 'components/ui/form/PaymentForm/messages'
import { createPayload } from 'components/ui/form/PaymentForm/schema'
import TokenForm from 'components/TokenForm'
import { FormBox, FlowBanner, HeaderBanner } from './styles'

import CloseIcon from '@material-ui/icons/Close'
import useRudderStack from 'utils/useRudderStack'
import { selectThreeDS } from 'reducers/threeds'
import { formatCurrency } from 'utils/format-currency'
import useInstallments from 'utils/useInstallments'
import { useWalletPayStore } from 'libs/useWalletPay'
import { selectUnicoPay } from 'reducers/unicopay'

function PaymentForm({ ...props }) {
  const { rudderStack } = useRudderStack()
  const theme = useTheme()
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'))
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const config = usePaymentContext()
  const session = useSelector(selectSession)
  const payments = useSelector(selectPayments)
  const dispatch = useDispatch()
  const router = useRouter()

  const [popup, setPopup] = useState({
    open: false,
  })
  const { info: infoPopup, open: openPopup, anchor, unknowError } = popup

  const [tokenForm, setTokenForm] = useState({
    open: false,
  })
  const { open: openTokenForm } = tokenForm

  const onCloseForm = useCallback(() => {
    dispatch(hideForm())
  }, [dispatch])

  const onFormClose = useCallback((event) => {
    setTokenForm({
      open: false,
    })
  }, [])

  const onPopupClose = useCallback(
    (event) => {
      if (unknowError) {
        location.reload()
      } else {
        setPopup((current) => {
          return {
            ...current,
            open: false,
            anchor: null,
          }
        })
      }
    },
    [unknowError],
  )

  const onHelperClick = useCallback(
    (event) => {
      onPopupClose()
      setTimeout(() => {
        setPopup(() => {
          return {
            open: true,
            info: warnings.cvv_help,
          }
        })
      }, 100)
    },
    [onPopupClose, setPopup],
  )

  const onSubmit = async (values, form) => {
    let payload = createPayload(values, config.invoiceData)

    const handle = config.userData.id
    const amount = config.amount.toFixed(2).replace('.', ',')
    const response = await dispatch(
      createPayment({
        handle,
        amount,
        payload,
        router,
        rudderStack,
      }),
    )

    const { meta, payload: result } = response
    if (meta.condition === false) {
      if (result?.startsWith('Email ')) {
        return {
          email: result,
        }
      }
      if (result == 'unknown_error') {
        showFinalError()
        return false
      }
      return {
        [FORM_ERROR]: result?.error || result,
      }
    }
  }
  const showFinalError = () => {
    rudderStack.track('Payment Link Error', {
      error: 'Unknown Error - PAY CONNECTION',
    })
    setPopup(() => {
      return {
        open: true,
        unknowError: true,
        info: warnings.unknown_error,
      }
    })
  }

  useEffect(() => {
    setTokenForm({
      open: Boolean(session.confirmation),
    })
  }, [session.confirmation])

  return (
    <FormBox component="section" expand={payments.active} {...props}>
      <Form
        onSubmit={onSubmit}
        onCloseForm={onCloseForm}
        component={Fieldset}
        closePopup={onPopupClose}
        handlePopup={setPopup}
      />
      <Popup
        fullScreen={matchesDownSm}
        maxWidth={matchesMd && 'sm'}
        open={openPopup && !anchor}
        title={infoPopup?.title}
        icon={infoPopup?.icon}
        button={infoPopup?.button}
        onClose={onPopupClose}
        onHelperClick={onHelperClick}
      >
        {infoPopup?.content ?? ''}
      </Popup>
      <Popover open={!!(anchor && openPopup)} anchor={anchor} onClose={onPopupClose}>
        {infoPopup?.content ?? ''}
      </Popover>
      <TokenForm open={openTokenForm} onClose={onFormClose} />
    </FormBox>
  )
}

const Fieldset = ({
  closePopup,
  handlePopup,
  handleSubmit,
  onCloseForm,
  submitting,
  submitted,
  submitError,
  pristine,
  form,
  hasValidationErrors,
  values,
}) => {
  const theme = useTheme()

  const payments = useSelector(selectPayments)
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'))
  const session = useSelector(selectSession)
  const zipCodeSearch = useSelector(selectZipCodeSearch)
  const dispatch = useDispatch()
  const config = usePaymentContext()
  const formSchema = useFormSchemaContext()
  const threeds = useSelector(selectThreeDS)
  const setInstallment = useWalletPayStore((state) => state.setInstallment);
  const unicopay = useSelector(selectUnicoPay)
  
  const baseAmount = config.amount
  const installments = values.installments

  useEffect(() => {
    if (installments) {
      setInstallment(installments)
    }
  }, [installments])

  const disabled =
    pristine ||
    submitting ||
    hasValidationErrors ||
    baseAmount === 0 ||
    threeds.loading ||
    unicopay.loading ||
    installments === 'default'
  const amount =
    installments > 0
      ? config.valueWithFees
        ? config.valueWithFees.at(installments - 1).installment_value
        : baseAmount / installments
      : baseAmount
  const { installmentsData } = useInstallments({ baseAmount })

  const onRequestZipCode = (value) => {
    setTimeout(() => {
      dispatch(fetchAddressByZipcode(value.replace(/\D/gi, '')))
    })
  }

  const onCVVHelp = (event) => {
    handlePopup({
      open: true,
      anchor: config.deviceDetection.desktop && matchesMd && event.currentTarget,
      info: warnings.cvv_help,
    })
  }

  useEffect(() => {
    form.batch(() => {
      const zipCode = Y.reach(formSchema.schema, 'billing_zip_code')
      if (zipCodeSearch.address) {
        zipCode.spec.meta = {
          valid: true,
        }
        form.change('billing_street', zipCodeSearch.address.logradouro)
        form.change('billing_city', zipCodeSearch.address.localidade)
        form.change('billing_state', zipCodeSearch.address.uf)
      } else if (zipCodeSearch.searched) {
        zipCode.spec.meta = {
          valid: false,
        }
        form.change('billing_street', '')
        form.change('billing_city', '')
        form.change('billing_state', '')
      }
    })
  }, [zipCodeSearch.address, zipCodeSearch.searched])

  // Check if was needed to authenticate before try pay again
  useEffect(() => {
    if (session.user && payments.signing) {
      handleSubmit()
    }
  }, [session.user, payments.signing])

  useEffect(() => {
    if (session.email_error) {
      handleSubmit()
    }
  }, [session.error])

  // Check submitError condition for Popup Alerts
  useEffect(() => {
    if (submitError || threeds.error || unicopay.error) {
      closePopup()
      const info = warningFindByCode(submitError || threeds.error || unicopay.error)

      setTimeout(() => {
        handlePopup(() => {
          return {
            open: true,
            info: info,
          }
        })
      }, 100)
    }
  }, [closePopup, handlePopup, submitError, threeds.error, unicopay.error])

  const { rudderStack, ready: rudderStackReady } = useRudderStack()

  useEffect(() => {
    if (submitError && rudderStackReady) {
      const info = warningFindByCode(submitError)
      rudderStack.track('Payment Link Error', {
        error: info.title,
        code: submitError,
        content: info.content,
      })
    }
  }, [submitError, rudderStackReady])

  useEffect(() => {
    if (config.transferFees && values.installments > 0) {
      config.setAmount(config.valueWithFees[values.installments - 1].total_value)
    }
  }, [config.transferFees, values.installments])

  return (
    <>
      <HeaderBanner
        amount={config.amount}
        valueWithFees={config.valueWithFees}
        installments={installments}
        handle={config.userData?.id}
      />
      <form onSubmit={handleSubmit} noValidate autoComplete="on">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="h6">Pagar compra</Typography>
              <IconButton
                className={``}
                aria-label="Fechar"
                onClick={onCloseForm}
                data-testid={ELEMENT_TEST_ID.closeButton}
              >
                <CloseIcon color="inherit" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <PhoneNumber />
          </Grid>
          <Grid item xs={12}>
            <Email />
          </Grid>
          <Grid item xs={12}>
            <Installments data={installmentsData} />
          </Grid>
          <Grid item xs={12}>
            <CardNumber />
          </Grid>
          <Grid item xs={6}>
            <CardExpDate />
          </Grid>
          <Grid item xs={6}>
            <CardCVV onHelp={onCVVHelp} />
          </Grid>
          <Grid item xs={12}>
            <CardHolderName />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Dados do titular do cartão</Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <CPF /> */}
            <CPFCNPJ />
          </Grid>
          <Grid item xs={12}>
            <CEP inputProps={{ onComplete: onRequestZipCode }} fetching={zipCodeSearch.fetching} />
          </Grid>
          <Grid item xs={12}>
            <Collapse in={!!zipCodeSearch.searched}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Street />
                </Grid>
                <Grid item xs={12}>
                  <City />
                </Grid>
                <Grid item xs={6}>
                  <StreetNumber />
                </Grid>
                <Grid item xs={6}>
                  <State />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              disabled={disabled}
              submitting={(submitting && !submitted) || threeds.loading || unicopay.loading}
              aria-invalid={hasValidationErrors}
              data-testid={ELEMENT_TEST_ID.payAmountButton}
            >
              {installments > 0
                ? `Pagar em ${installments}x de ${formatCurrency(amount)}`
                : `Pagar ${formatCurrency(amount)}`}
            </SubmitButton>
          </Grid>
          <Grid item xs={12}>
            <Hidden mdUp>
              <Footer
                style={{
                  margin: '0 auto',
                }}
              />
            </Hidden>
            <br />
          </Grid>
        </Grid>
      </form>
      <Hidden mdUp>
        <FlowBanner />
      </Hidden>
    </>
  )
}

export default PaymentForm
