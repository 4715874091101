import BaseForm, { TextField, useFormSchemaContext } from '../'
import { schema as formSchema, required, validate, initialValues } from './schema'
import ELEMENT_TEST_ID from '/playwright/support/elements'

const Form = ({ ...props }) => (
  <BaseForm {...props} {...{ schema: formSchema, required, validate, initialValues }} />
)
export default Form

export function Token({ name = 'token', label = undefined }) {
  const schema = useFormSchemaContext()
  return (
    <TextField
      label={label}
      name={name}
      type="tel"
      inputProps={{ mask: '000000', 'data-testid': ELEMENT_TEST_ID.tokenInput}}
      placeholder="000000"
      required={schema.required[name]}
    />
  )
}
