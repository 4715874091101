import { Box } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import Image from 'next/image'
import ELEMENT_TEST_ID from '/playwright/support/elements'
import VisaIcon from 'assets/card-brands/visa.svg'
import MasterCardIcon from 'assets/card-brands/mastercard.svg'
import EloIcon from 'assets/card-brands/elo.svg'
import AmexIcon from 'assets/card-brands/amex.svg'
import HiperIcon from 'assets/card-brands/hiper.svg'
import VisaGreyIcon from 'assets/card-brands/visa.grey.svg'
import MasterCardGreyIcon from 'assets/card-brands/mastercard.grey.svg'
import EloGreyIcon from 'assets/card-brands/elo.grey.svg'
import AmexGreyIcon from 'assets/card-brands/amex.grey.svg'
import HiperGreyIcon from 'assets/card-brands/hiper.grey.svg'
import CVVIcon from 'assets/card-cvv.svg'

const Visa = ({scale = 1}) => {
  return <Image src={VisaIcon} width={58 * scale} height={36 * scale} alt="Visa" data-testid={ELEMENT_TEST_ID.receiptCardBrandImage} />
}

const VisaGrey = ({scale = 1}) => {
  return <Image src={VisaGreyIcon} width={54 * scale} height={45 * scale} alt="Visa" data-testid={ELEMENT_TEST_ID.receiptCardBrandImage} />
}

const MasterCard = ({scale = 1}) => {
  return <Image src={MasterCardIcon} width={49 * scale} height={36 * scale} alt="MasterCard" data-testid={ELEMENT_TEST_ID.receiptCardBrandImage} />
}

const MasterCardGrey = ({scale = 1}) => {
  return <Image src={MasterCardGreyIcon} width={69 * scale} height={45 * scale} alt="MasterCard" data-testid={ELEMENT_TEST_ID.receiptCardBrandImage} />
}

const Elo = ({scale = 1}) => {
  return <Image src={EloIcon} width={63 * scale} height={36 * scale} alt="Elo" data-testid={ELEMENT_TEST_ID.receiptCardBrandImage} />
}

const EloGrey = ({scale = 1}) => {
  return <Image src={EloGreyIcon} width={69 * scale} height={45 * scale} alt="Elo" data-testid={ELEMENT_TEST_ID.receiptCardBrandImage} />
}

const Amex = ({scale = 1}) => {
  return <Image src={AmexIcon} width={45 * scale} height={36 * scale} alt="Amex" data-testid={ELEMENT_TEST_ID.receiptCardBrandImage} />
}

const AmexGrey = ({scale = 1}) => {
  return <Image src={AmexGreyIcon} width={69 * scale} height={45 * scale} alt="Amex" data-testid={ELEMENT_TEST_ID.receiptCardBrandImage} />
}

const Hiper = ({scale = 1}) => {
  return <Image src={HiperIcon} width={64 * scale} height={36 * scale} alt="Hiper" data-testid={ELEMENT_TEST_ID.receiptCardBrandImage} />
}

const HiperGrey = ({scale = 1}) => {
  return <Image src={HiperGreyIcon} width={69 * scale} height={45 * scale} alt="Hiper" data-testid={ELEMENT_TEST_ID.receiptCardBrandImage} />
}

const CVV = ({scale = 1}) => {
  return <Image src={CVVIcon} width={58 * scale} height={36 * scale} alt="CVV" />
}

const Cards = styled(({ children, ...props }) => (
  <Box
    component="ul"
    display="flex"
    flexDirection="row"
    flexWrap="nowrap"
    justifyContent="flex-start"
    p={0}
    m={0}
    mt={0.5}
    {...props}
  >
    {children}
  </Box>
))(({ theme: { spacing } }) => ({
  listStyleType: 'none',
}))

const CardBrand = styled(({ children, ...props }) => (
  <Box
    component="li"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flex="0 0 auto"
    m={0}
    ml={1}
    p={0}
    {...props}
  >
    {children}
  </Box>
))(({ theme: { palette, spacing }, ...props }) => ({
  minWidth: '2.5rem',
  height: '1.875rem',
  border: `1px solid ${palette.input.background}`,
  borderRadius: spacing(0.5),
  img: {
    display: 'block',
  },
}))

const Brands = (props) => {
  const cardComponents = [Visa, MasterCard, Elo, Amex, Hiper];

  return (
    <Cards {...props}>
      {cardComponents.map((Card, index) => (
        <CardBrand key={index}>
          <Card />
        </CardBrand>
      ))}
    </Cards>
  );
};

export default Brands

export {
  Visa,
  VisaGrey,
  MasterCard,
  MasterCardGrey,
  Elo,
  EloGrey,
  Amex,
  AmexGrey,
  Hiper,
  HiperGrey,
  CVV,
}

export function getBrandByCardType(type, grey = false) {
  const types = {
    visa: grey ? VisaGrey : Visa,
    mastercard: grey ? MasterCardGrey : MasterCard,
    elo: grey ? EloGrey : Elo,
    hipercard: grey ? HiperGrey : Hiper,
    amex: grey ? AmexGrey : Amex
  }
  return types[type]
}
