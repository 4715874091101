import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { infinitePayLinkBanner } from 'config'
import BannerLink from 'components/ui/BannerLink'
import { Box, Typography, useMediaQuery } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import backgroundVisa from 'assets/banner-visa.png'
import backgroundVisaMobile from 'assets/banner-visa-mobile.png'
import { BannerButton } from 'components/NewHeader/styles'
import { formatCurrency } from 'utils/format-currency'

export const FormBox = withStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      backgroundColor: theme.palette.common.white,
      zIndex: 10,
      height: 0,
      width: '100%',

      transition: 'width .369s ease-out, height .369s ease-out',
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        width: 0,
        height: '100vh',
      },
      '& > form': {
        position: 'relative',
        margin: '0 auto',
        width: '100%',
        maxWidth: 375,
        padding: theme.spacing(2),
        minHeight: '100vh',
      },
    },
    expand: {
      height: 'fit-content',
      overflow: 'initial',
      '& > form': {
        minHeight: 'calc(100vh - 84px)',
        [theme.breakpoints.up('md')]: {
          minHeight: 'calc(100vh - 168px)',
        },
      },
      [theme.breakpoints.up('md')]: {
        height: '100vh',
        overflow: 'auto',
        width: '100%',
        '-webkit-overflow-scrolling': 'touch',
        '& > form': {
          minHeight: '100vh',
        },
      },
    },
  }),
  {
    name: 'FormBox',
  },
)(({ expand, classes, ...props }) => (
  <Box className={clsx(classes.root, { [classes.expand]: expand })} {...props} />
))

export const FlowBanner = withStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: 0,
      paddingBottom: '41.6%',
      textDecoration: 'none',
    },
    backgroundVisa: {
      background: `transparent url(${backgroundVisa.src}) no-repeat center`,
      backgroundSize: 'auto 100%',
    },
    backgroundMobile: {
      background: `transparent url(${backgroundVisaMobile.src}) no-repeat center`,
      backgroundSize: 'auto 100%',
    },
    textContainer: {
      textAlign: 'left',
      marginLeft: '6%',
      display: 'flex',
      flexDirection: 'column',
    },
    textTypography: {
      fontWeight: 600,
      display: 'inline-block',
      fontSize: '9px',
      lineHeight: '14px',
      color: '#000000',
      width: '180px',
    },
    textTypographyTitle: {
      fontSize: '16px',
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      height: '36px',
      color: '#000000',
      width: '180px',
      lineHeight: '18px',
      marginTop: '8%',
    },
    button: {
      background: '#000000',
      color: '#FFFFFF',
      '&:hover': {
        transform: 'scale(0.94)',
        background: '#000000',
      },
      height: '21px',
      width: '118px',
      fontSize: '8px',
    },
  }),
  {
    name: 'FlowBanner',
  },
)(({ expand, classes, invalid, ...props }) => {
  return (
    <BannerLink
      href={infinitePayLinkBanner.mobile}
      className={clsx(classes.root, isMobile ? classes.backgroundMobile : classes.backgroundVisa)}
      {...props}
    >
      <Box className={classes.textContainer}>
        <Typography className={clsx(classes.textTypographyTitle)}>
          Link de Pagamento InfinitePay
        </Typography>
        <Typography className={clsx(classes.textTypography)}>
          Venda segura e recebimento na hora. <br />O link favorito de quem vende online.
        </Typography>
        <BannerButton className={classes.button}>Cadastre-se grátis</BannerButton>
      </Box>
    </BannerLink>
  )
})

export const HeaderBanner = withStyles(
  (theme) => ({
    root: {
      width: '100vw',
      backgroundColor: '#171527',
      height: '100px',
      color: '#ffffff',
      display: isMobile ? 'flex' : 'none',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    tittleTypography: {
      fontWeight: 300,
      fontSize: '14px',
    },
    textTypography: {
      fontWeight: 500,
      fontSize: '24px',
      color: '#ffffff',
    },
    textTypographyInstallments: {
      fontWeight: 500,
      fontSize: '12px',
      color: '#9e9e9e',
    },
  }),
  {
    name: 'FlowBanner',
  },
)(({ classes, amount, installments, valueWithFees, handle }) => (
  <Box className={clsx(classes.root)}>
    <Typography className={clsx(classes.tittleTypography)}>Pague para ${handle}</Typography>
    <Typography className={clsx(classes.textTypography)}>{formatCurrency(amount)}</Typography>
    {
      valueWithFees && installments > 0 && <Typography className={clsx(classes.textTypographyInstallments)}>{`${installments}x de ${formatCurrency(valueWithFees[installments-1]?.installment_value)}`}</Typography>
    }
    
  </Box>
))
